<template>
  <div>
    <Nav></Nav>
    <div v-if="clientWidth > 900" class="content" :style="{ width: isProduct ? '100%' : '90%' }" style="min-height: calc(100vh - 295px)" v-loading="loading">
      <el-row>
        <el-col :span="24">
          <div style="text-align: center">
            <div v-if="!isProduct" style="margin: 20px">
              <h1>{{ info.title }}</h1>
            </div>
            <div v-if="this.$route.query.id === '150'" class="article_info" v-html="hhgContent" style="text-alain: left"></div>
            <div v-else-if="this.$route.query.id === '151'" class="article_info" v-html="fclContent" style="text-alain: left"></div>
            <div v-else class="article_info" v-html="info.content"></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else class="content" :style="{ width: isProduct ? '100%' : '90%' }" style="min-height: calc(100vh - 255px)" v-loading="loading">
      <el-row>
        <el-col :span="24">
          <div style="text-align: center">
            <div v-if="!isProduct" style="margin: 20px">
              <h1>{{ info.title }}</h1>
              <div v-if="!isProduct" style="margin: 12px; color: #aaaaaa; font-size: 12px">时间：{{ dateFormat(info.timeline) }}</div>
            </div>
            <div v-if="this.$route.query.id === '150'" class="article_info" v-html="hhgContent" style="text-alain: left"></div>
            <div v-else-if="this.$route.query.id === '151'" class="article_info" v-html="fclContent" style="text-alain: left"></div>
            <div v-else class="article_info" v-html="info.content"></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import articleApi from '../api/article.js'
import moment from 'moment'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      loading: true,
      hhgContent: '<div style="text-align:center;"><img src="/images/attachment/201709/03/202203250HHG.jpg" style="width:100%" alt="" border="0" /></div>',
      fclContent: '<p><img class="wscnph" src="/images/attachment/201709/02/fcl_00.jpg" style="width:100%" /><img class="wscnph" style="width:100%" src="/images/attachment/201709/02/fcl_01.jpg" /><img class="wscnph" style="width:100%" src="/images/attachment/201709/02/fcl_02.jpg" /></p>',
      info: {},
      title: '',
      liList: ['1', '2', '2', '2', '2', '2'],
      pics: [
        { src: '../images/index/101.jpg', url: 'https://www.jfkbio.com/article/8' },
        { src: '../images/index/102.jpg', url: 'https://www.jfkbio.com/article/1' },
        { src: '../images/index/103.jpg', url: 'https://www.jfkbio.com/article/38' },
        { src: '../images/index/104.jpg', url: 'https://www.jfkbio.com/article/7' }
      ],
      clientWidth: document.body.clientWidth,
      isProduct: false,
      titleArr: ['呵呵管', '菲长乐', '呵佑宁', '呵佐安', '菲佑宁', '菲佐安', '公司介绍', '发展历程与规划', '专利证书', '招贤纳士', '合作伙伴', '联系方式']
    }
  },
  methods: {
    getArticleById(id) {
      this.info = {}
      this.loading = true
      var that = this
      articleApi
        .getArticleList(id)
        .then((res) => {
          that.info = res.data.data.info
          that.title = res.data.data.title
          that.liList = res.data.data.list
          var i = this.titleArr.indexOf(that.info.title)
          if (i > -1) {
            that.isProduct = true
          } else {
            that.isProduct = false
          }
          that.loading = false
        })
        .catch(function (error) {})
    },
    init() {
      this.getArticleById(this.$route.params.id)
    },
    dateFormat(timeline) {
      var time = timeline * 1000
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  mounted() {
    console.log('123456789--' + this.$route.params.id)
    this.init()
  },
  watch: {
    $route: 'init'
  }
}
</script>

<style>
.article_info div,
.article_info {
  text-align: left;
  padding-bottom: 20px;
}
.left_box {
  text-align: left;
  padding-left: 50px;
  margin-top: 50px;
}
.left_box ul,
.left_box li {
  list-style: inside;
}
.left_box li {
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  color: #444444;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
  padding-left: 10px;
}
.lllllll a {
  color: #2c3e50;
}
</style>
